
  export default {
    name: "AppMast",
    methods: {
      buttonAnimate(event: { target: HTMLInputElement }) {
        if (event && event.target) {
          event.target.classList.add('animate');
          setTimeout(() => {
            event.target.classList.remove('animate');
          }, 700);
        }
      }
    }
  }
