<template>
    <div id="disclaimer">
        <Octicon icon="alert" :width="20" ariaLabel="WARNING!" /> <strong>Disclaimer:</strong> This website does not provide medical advice. Always check the label for allergens as trace ingredients in products can change over time.
    </div>
</template>
<script>
    import Octicon from '@/components/Octicon.vue';

    export default {
        name: 'Disclaimer',
        components: {
            Octicon
        }
    }
</script>
<style scoped lang="scss">
    #disclaimer {
        background: #FFF8C4;
        text-align: left;
        padding: 1em;
        line-height: 22px;
        color: #2c3e50;
        @media (min-width: 780px) {
            text-align: center;
        }
        span.alert {
            display: inline-block;
            vertical-align: middle;
            line-height: 14px;
        }
    }
</style>