import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: 'About | ${process.env.VUE_APP_NAME}',
      metaTags: [
        {
          name: 'description',
          content: 'All about ${process.env.VUE_APP_NAME}'
        },
        {
          property: 'og:description',
          content: 'Find out all about ${process.env.VUE_APP_NAME}'
        }
      ]
    }
  },
  {
    path: "/swap",
    name: "Swap",
    component: () =>
      import(/* webpackChunkName: "swap" */ "../views/Swap.vue"),
  },
  {
    path: "/results",
    name: "Results",
    component: () =>
      import(/* webpackChunkName: "results" */ "../views/Results.vue"),
  },
  {
    path: "/print",
    name: "Print",
    component: () =>
      import(/* webpackChunkName: "print" */ "../views/Print.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
});

export default router;
