<template>
  <Disclaimer />
  <div id="logo">
    <h1 v-if="!isHome"><router-link to="/"><Octicon icon="arrow-switch" :width="16" ariaLabel="Logo" /> Ingredient Swap</router-link></h1>
    <h1 v-if="isHome"><Octicon icon="arrow-switch" :width="16" ariaLabel="Logo" /> Ingredient Swap</h1>
  </div>
  <router-view v-slot="{ Component }">
    <transition name="scale" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <div>Background Photo by <a href="https://unsplash.com/@calumlewis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noopener">Calum Lewis</a> on <a href="https://unsplash.com/s/photos/ingredients?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noopener">Unsplash</a></div>
</template>

<script>
  import Disclaimer from '@/components/Disclaimer.vue';
  import Octicon from '@/components/Octicon.vue';

  export default {
    components: {
      Disclaimer,
      Octicon
    },
    beforeCreate() {
      this.$store.commit('initialiseStore');
    },
    computed: {
      isHome() {
        return this.$route.name === 'Home';
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
  }
  #logo {
    h1 {
      color: #000;
      text-shadow: rgba(255,255,255,0.8) 0 0 10px;
      margin: 1em 0;
      font-size: 32px;
      @media (min-width: $sm) {
        font-size: 50px;
      }
      font-weight: 900;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
    .arrow-switch {
      color: #000;
      svg {
        fill: currentColor;
        filter: drop-shadow(0px 3px 3px rgba(255, 255, 255, 0.8));
        width: 20px;
        height: 20px;
        @media (min-width: $sm) {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
  p {
    font-size: 1.5em;
    line-height: 1em;
    font-weight: bold;
    color: #fff;
    text-shadow: rgba(0,0,0,0.9) 0 0 10px;
  }
  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #fff;

      &.router-link-exact-active {
        color: #ddd;
      }
    }
  }
  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.5s ease;
  }

  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
</style>
