import { createStore } from "vuex";

export default createStore({
  state: {
    originalIngredients: [],
    hasAllergies: false,
    allergiesAndIntolerances: [],
    hasDietaryPreference: false,
    dietaryPreference: ''
  },
  mutations: {
    initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('store')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store') || ""))
				);
			}
		},
    updateOriginalIngredients(state, value) {
      state.originalIngredients = value.split('\n');
    },
    updateHasAllergies(state, value) {
      state.hasAllergies = value;
    },
    updateAllergiesAndIntolerances(state, value) {
      state.allergiesAndIntolerances = value;
    },
    updateHasDietaryPreference(state, value) {
      state.hasDietaryPreference = value;
    },
    updateDietaryPreference(state, value) {
      state.dietaryPreference = value;
    }
  },
  actions: {},
  modules: {},
});
